import ApiServices from './ApiServices';

class PaymentsService {

    createPaymentBeneficiary(paymentRequestId, accountId, successCallback, moreInfoCollect, failureCallback) {
        var postObject = {
            accountId
        };
        var url = "/payment/" + paymentRequestId + "/beneficiaries";
        var operationName = "Payment Beneficiary Creation";
        ApiServices.performPostOperationWithRetryUserInput(url, postObject, successCallback, moreInfoCollect, failureCallback, operationName);
    }

    processPayment(paymentRequestId, accountId, successCallback, moreInfoCollect, failureCallback) {
        var postObject = {
            accountId
        };
        var url = "/payment/" + paymentRequestId;
        var operationName = "Payment Processing";
        ApiServices.performPostOperationWithRetryUserInput(url, postObject, successCallback, moreInfoCollect, failureCallback, operationName);
    }

    processUiPayment(paymentRequestId, redirectUrl, successCallback, failureCallback) {
        var putObject = {
            redirectUrl
        };
        var url = "/payment/" + paymentRequestId;
        var operationName = "Payment Ui Processing";
        ApiServices.performPutOperationNoRetry(url, putObject, successCallback, failureCallback, operationName);
    }

    getPaymentInfo(paymentRequestId, successCallback, failureCallback) {
        var url = "/payment/" + paymentRequestId;
        var operationName = "Payment Retrieval";
        ApiServices.performGetOperationNoRetry(url, successCallback, failureCallback, operationName);
    }

}

const service = new PaymentsService();
  
export default service;
