import ApiServices from './ApiServices';
import PaymentsService from './PaymentsService';

class AiiaService {

    saveConfig(configData){
        this.config = {
            serviceUrl: configData["config.aiia.serviceurl"],
            clientid: configData["config.aiia.clientid"],
            environment: configData["config.aiia.environment"]
        }
        console.log("Aiia Service Config", this.config);
    }

    addAiiaUser(aiiaUser, successCallback, failureCallback) {
        var postObject = aiiaUser;
        var url = "/aiia/user/connections";
        var operationName = "Add Aiia User";
        ApiServices.performPostOperationNoRetry(url, postObject, successCallback, failureCallback, operationName);
    }

    getRedirectUri(){
        return new URL("/aiia/login", window.location.href);
    }

    getPaymentRedirectUri(){
        return new URL("/aiia/payment", window.location.href);
    }

    launchAiiaUi(user, addUserSuccess, addUserFailed) {
        let url = new URL("/v1/oauth/connect", this.config.serviceUrl);
        url.searchParams.set('client_id', this.config.clientid);
        url.searchParams.set("redirect_uri", this.getRedirectUri());
        url.searchParams.set("response_type", "code");
        url.searchParams.set("scope", "accounts offline_access payments:outbound payments:inbound");

        window.open(url, 
            "_top", 
            "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    }

    launchAiiaPaymentUi(paymentRequestId, paymentUiSuccess, paymentUiFailed) {
        PaymentsService.processUiPayment(paymentRequestId, this.getPaymentRedirectUri(), (response) =>{
            let url = new URL(response.data.redirectUrl);
            window.open(url, 
                "_top", 
                "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
        }, (ex)=>{
            paymentUiFailed(ex);
        });
    }

}

const service = new AiiaService();
  
export default service;
