import React, { Fragment, Suspense, lazy, useState, useEffect } from "react";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";

import UserService from './service/UserService';
import ConfigService from './service/ConfigService';
import AiiaService from './service/AiiaService';
import DapiService from './service/DapiService';

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));


function App() {
    const [user, setUser] = useState(null);

    const onLoad = () => {
        ConfigService.loadConfig(
            (response) => {
                console.log("Config Response", response);
                AiiaService.saveConfig(response.data);
                DapiService.saveConfig(response.data);
                UserService.refreshUser(
                    (response) => {
                        if (!(response.data.user.userRole === "TEMP")) {
                            console.log("User is still active", response);
                            //const history = useHistory();
                            setUser(response.data.user);
                            //history.push("/c/dashboard");
                        }
                    },
                    (ex) => {
                        console.log("User is no longer active", ex);
                    }
                );
            },
            (ex) => {
                //TODO: Handle Config Error
                console.log("Config Error", ex);
            }
        );
    };

    const onLogout = () => {
        UserService.logout();
        setUser(null);
    };
    useEffect(onLoad, []);

    return (
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyles />
                    <Pace color={theme.palette.primary.light} />
                    <Suspense fallback={<Fragment />}>
                        <Switch>
                            <Route path="/c">
                                <LoggedInComponent logout={onLogout} user={user} />
                            </Route>
                            <Route>
                                <LoggedOutComponent logout={onLogout} user={user} />
                            </Route>
                        </Switch>
                    </Suspense>
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    );
};

export default App;
