import axios from 'axios'
import ApiServices from './ApiServices';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUserName'
export const USER_OBJECT_SESSION_ATTRIBUTE_NAME = 'authenticatedUserObject'
export const TOKEN_ATTRIBUTE_NAME = 'authToken'

class UserService {

    createUser(name, surname, email, password, successCallback, failureCallback) {
        var postObject = {
            name,
            surname,
            email,
            password
        };
        var url = "/user";
        var operationName = "User Registration";
        ApiServices.performPostOperationNoRetry(url, postObject, successCallback, failureCallback, operationName);
    } 

    confirmUser(token, successCallback, failureCallback) {
        var formData = new FormData();
        formData.append('token', token);
        var url = "/user/confirm";
        var operationName = "User Confirmation";
        ApiServices.performPostOperationFormDataNoRetry(url, formData, successCallback, failureCallback, operationName);
    }

    executeJwtAuthentication(username, password, successCallback, failureCallback) {
        var postObject = {
            username,
            password
        };
        var url = "/authenticate";
        var operationName = "Authentication";
        ApiServices.performPostOperationNoRetry(url, postObject, successCallback, failureCallback, operationName);
    }

    createTempUser(successCallback, failureCallback) {
        var url = "/tempuser";
        var operationName = "Temp User Creation";
        ApiServices.performPostOperationNoBodyNoRetry(url, successCallback, failureCallback, operationName);
    }

    refreshUser(successCallback, failureCallback) {
        let token = localStorage.getItem(TOKEN_ATTRIBUTE_NAME);
        var additionalHeaders = {
            authorization: this.createJWTToken(token)
        };
        var url = "/refresh";
        var operationName = "User Refresh";
        ApiServices.performGetOperationNoRetryWithAdditionalHeaders(url, additionalHeaders, successCallback, failureCallback, operationName);
    }

    registerSuccessfulLoginForJwt(username, user, token) {
        localStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
        localStorage.setItem(USER_OBJECT_SESSION_ATTRIBUTE_NAME, JSON.stringify(user));
        localStorage.setItem(TOKEN_ATTRIBUTE_NAME, token);
        this.setupAxiosInterceptors(this.createJWTToken(token));
    }

    registerTempLoginForJwt(token) {
        this.setupAxiosInterceptors(this.createJWTToken(token));
    }

    createJWTToken(token) {
        return 'Bearer ' + token
    }

    logout() {
        this.clearAxiosInterceptors();
        localStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
        localStorage.removeItem(USER_OBJECT_SESSION_ATTRIBUTE_NAME);
        localStorage.removeItem(TOKEN_ATTRIBUTE_NAME);
    }

    isUserLoggedIn() {
        let user = localStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let userName = localStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (userName === null) return ''
        return userName
    }

    getLoggedInUserId() {
        let userObject = JSON.parse(localStorage.getItem(USER_OBJECT_SESSION_ATTRIBUTE_NAME))
        if (userObject === null) return null
        return userObject.id;
    }

    getLoggedInUserObject() {
        let userObject = JSON.parse(localStorage.getItem(USER_OBJECT_SESSION_ATTRIBUTE_NAME))
        return userObject
    }

    setupAxiosInterceptors(token) {
        this.authorizationInterceptor = axios.interceptors.request.use(
            (config) => {
                config.headers.authorization = token;
                return config;
            }
        )
    }

    clearAxiosInterceptors() {
        axios.interceptors.request.eject(this.authorizationInterceptor);
    }

}

const service = new UserService();
  
export default service;
