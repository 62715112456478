import ApiServices from './ApiServices';

class ConfigService {

    loadConfig(successCallback, failureCallback) {
        var url = "/config";
        var operationName = "Loading Config";
        ApiServices.performGetOperationNoRetry(url, successCallback, failureCallback, operationName);
    }

}

const service = new ConfigService();
  
export default service;
