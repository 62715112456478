/* global Dapi */

import ApiServices from './ApiServices';

class DapiService {

    saveConfig(configData){
        this.config = {
            appKey: configData["config.dapi.appkey"],
            environment: configData["config.dapi.environment"],
            countries: configData["config.dapi.countries"].split(','),
            bundleId: configData["config.dapi.bundleid"]
        }
        console.log("Dapi Service Config", this.config);
    }

    addDapiUser(dapiUser, successCallback, failureCallback) {
        var postObject = dapiUser;
        var url = "/dapi/user/connections";
        var operationName = "Add Dapi User";
        ApiServices.performPostOperationNoRetry(url, postObject, successCallback, failureCallback, operationName);
    }

    launchDapiUi(user, addUserSuccess, addUserFailed) {
        var dapi = this.createDapiEnvironment(user, addUserSuccess, addUserFailed);
        dapi.open();
    }

    createDapiEnvironment(user, addUserSuccess, addUserFailed) {
        var appKey = this.config.appKey;
        var environment = this.config.environment;
        var bundleID = this.config.bundleId;
        var countries = this.config.countries;
        var userId = user.id;
        return Dapi.create({
            environment: environment,
            appKey: appKey,
            countries: countries,
            bundleID: bundleID,
            clientUserID: userId,
            isCachedEnabled: true,
            isExperimental: false,
            clientHeaders: {},
            clientBody: {},
            onSuccess: (dapiUser) => {
                console.log("Success");
                console.log(dapiUser);
                this.addDapiUser(dapiUser,
                    (response) => {
                        console.log("Dapi Connection Added");
                        addUserSuccess(response);
                    },
                    (ex) => {
                        console.log("Dapi Connection Failed");
                        addUserFailed(ex);
                    });
            },
            onFailure: (e) => {
                console.log("Failure");
                console.log(e);
            },
            onExit: () => {
                console.log("User exited the flow");
            },
            onReady: (r) => {
            }
        });
    }
}

const service = new DapiService();
  
export default service;
